import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'

if (!dayjs.prototype.utc) dayjs.extend(utc)

export const MINUTE = 1_000 * 60
export const FIVE_MINUTE = 5 * MINUTE
export const HOUR = 60 * MINUTE
export const DAY = HOUR * 24
export const WEEK = HOUR * 24 * 7
export const MONTH = HOUR * 24 * 30

// Prevent excessive bandwidth usage.
export const MINIMUM_RESOLUTIONS = [
    { range: MONTH * 6,  min: DAY },        // 140 data points
    { range: MONTH * 3,  min: HOUR * 8 },   // 270 data points
    { range: MONTH,      min: HOUR * 4 },   // 180 data points
    { range: WEEK,       min: MINUTE * 30 },// 334 data points
    { range: DAY,        min: MINUTE * 5 }, // 288 data points
    { range: HOUR * 12,  min: MINUTE * 2 }, // 359 data points
    { range: HOUR,       min: MINUTE },     // 60 data points
    { range: 0,          min: MINUTE },     // fallback
]

// Interval between data points
export const VALID_RESOLUTIONS = MINIMUM_RESOLUTIONS.map(r => r.min)

export function floorToNearestNthMinute (n = 1, date = new Date()) {
    const coeff = 1000 * 60 * n
    return new Date(Math.floor(date.getTime() / coeff) * coeff)
}

export function getResolutionForDateRange (from, to) {
    from = dayjs.utc(from)
    to = dayjs.utc(to)

    const diffMs = to.diff(from, 'ms')

    const resolution = MINIMUM_RESOLUTIONS.find(r => diffMs >= r.range)?.min ?? HOUR
    return resolution
}
