import dayjs from 'dayjs';
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue';
import { useRouteQuery } from '@vueuse/router'

import {
    HOUR, DAY, MONTH, getResolutionForDateRange
} from 'common/date-resolutions.js'

export const DATA_START_DATE = new Date('2023-08-11')
export const NOW_KEY = 'now'
export const ranges = [
    { label: 'Past 1 hour',   key: 'now-1h',  pastMs: HOUR },
    { label: 'Past 12 hours', key: 'now-12h', pastMs: HOUR * 12 },
    { label: 'Past 24 hours', key: 'now-24h', pastMs: DAY },
    { label: 'Past 7 days',   key: 'now-7d',  pastMs: DAY * 7 },
    { label: 'Past 30 days',  key: 'now-30d', pastMs: DAY * 30 },
    { label: 'Past 90 days',  key: 'now-90d', pastMs: DAY * 90 },
    { label: 'Past 6 months', key: 'now-6M',  pastMs: MONTH * 6 },
    { label: 'Past 1 year',   key: 'now-1Y',  pastMs: MONTH * 12 },
]

export const useDateRangeStore = defineStore('dateRange', () => {
    const defaultFrom = 'now-24h'
    const defaultTo = NOW_KEY

    const from = useRouteQuery('from', defaultFrom)
    const to = useRouteQuery('to', defaultTo)
    const lastUpdated = ref(new Date())

    // Update browser URL
    if (from.value === defaultFrom) {
        from.value = defaultFrom
    }
    if (to.value === defaultTo) {
        to.value = defaultTo
    }

    const _computed = (cb) => {
        return computed(() => {
            lastUpdated.value // create reactive dependency
            return cb()
        })
    }

    const fromTimestamp = _computed(() => convertToTimestamp(from.value))
    const toTimestamp = _computed(() => convertToTimestamp(to.value))

    const fromDate = _computed(() => convertToDate(from.value))
    const toDate = _computed(() => convertToDate(to.value))

    const resolution = _computed(() => getResolutionForDateRange(fromTimestamp.value, toTimestamp.value))

    const setFrom = val => {
        const isRangeKey = ranges.find(r => r.key === val)
        from.value = isRangeKey ? val : convertToTimestamp(val)
    }
    const setTo = val => {
        const isRangeKey = ranges.find(r => r.key === val)
        if (val === NOW_KEY) {
            to.value = val
        } else {
            to.value = isRangeKey ? val : convertToTimestamp(val)
        }
    }
    const refresh = () => lastUpdated.value = new Date()

    return {
        from,
        to,
        fromTimestamp,
        toTimestamp,
        fromDate,
        toDate,
        resolution,
        setFrom,
        setTo,
        lastUpdated,
        refresh,
    }
})

export const useDateRangeStoreRefs = () => storeToRefs(useDateRangeStore())

export function convertToTimestamp (val) {
    const range = ranges.find(r => r.key === val)
    if (val === NOW_KEY) {
        return dayjs.utc().startOf('m').valueOf()
    } else if (range) {
        return dayjs.utc().subtract(range.pastMs, 'ms').startOf('m').valueOf()
    } else if (val instanceof Date) {
        return dayjs.utc(val).startOf('m').valueOf()
    } else {
        return Number(val)
    }
}

export function convertToDate (queryVal) {
    const ts = convertToTimestamp(queryVal)
    return new Date(ts)
}
