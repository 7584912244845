import { createRouter, createWebHistory } from 'vue-router'

import { ALL_ASSETS } from 'common/constants.js';
import { useWallet } from '@/use/use-wallet.js'
import FolksView from '@/views/FolksView.vue'
import FolksMarkets from '@/views/FolksMarkets.vue'
import FolksLoanHistory from '@/views/FolksLoanHistory.vue'
import FolksAlerts from '@/views/FolksAlerts.vue'
import FolksBackfillData from '@/views/FolksBackfillData.vue'
import PricingPage from '@/views/PricingPage.vue'
import PageNotFound from '@/views/PageNotFound.vue'

const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        {
            path: '/',
            redirect: `/folks-finance/markets/all`
        },
        {
            path: '/folks-finance',
            children: [
                {
                    path: '',
                    redirect: `/folks-finance/markets`,
                },
                {
                    path: 'markets',
                    children: [
                        {
                            path: '',
                            redirect: `/folks-finance/markets/${ALL_ASSETS}`
                        },
                        {
                            path: ':assetId',
                            component: FolksMarkets,
                            props: true,
                        },
                    ]
                },
                {
                    path: 'loans',
                    component: PricingPage
                },
                {
                    path: 'liquidations',
                    component: PricingPage
                },
                {
                    path: 'lending-pools',
                    component: PricingPage
                },
                {
                    path: 'your-dashboard',
                    meta: { requiresAuth: true },
                    children: [
                        {
                            path: 'loan-history',
                            component: PricingPage
                            //component: FolksLoanHistory,
                        },
                        {
                            path: 'alerts',
                            component: PricingPage
                            //component: FolksAlerts
                        },
                        {
                            path: 'backfill-data',
                            component: PricingPage
                            //component: FolksBackfillData
                        },
                    ]
                },
            ]
        },
        {
            path: '/pricing',
            component: PricingPage
        },
        {
            path: '/:pathMatch(.*)*',
            component: PageNotFound
        },
    ]
})

router.beforeEach((to, from) => {
    // const wallet = useWallet()

    // if (to.meta.requiresAuth && !wallet.activeAddress.value) {
    //     return {
    //         path: '/login',
    //         // save the location we were at to come back later
    //         query: { redirect: to.fullPath },
    //     }
    // }
})

export default router
