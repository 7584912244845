<script setup>
import { ref } from 'vue'

import { EBISU_DONATION_ADDRESS } from 'common/constants.js'
import Modal from '@/components/Modal.vue'

const isOpen = ref(false)
</script>

<template>
    <button @click="isOpen = true" class="button is-dark is-responsive">
        <span class="icon is-small">
            <fa-icon icon="fa-solid fa-info" />
        </span>
    </button>
    <Modal v-model="isOpen">
        <p class="block">
            <span class="icon is-large">
                😍
            </span>
            <div>
                This is a passion project created by an Algorand community member.<br>
                It is not affiliated with Folks Finance or any other project.
            </div>
        </p>
        <p class="block">
            <span class="icon is-large">
                📧
            </span>
            <div>
                Email any feature requests, questions, comments, or bug reports to<br>
                <a href="mailto:ebisu.algo@gmail.com" target="_blank" class="has-text-link">ebisu.algo@gmail.com</a>
            </div>
        </p>
        <p class="block">
            <span class="icon is-large">
                🙏
            </span>
            <div>
                Donations are much appreciated and will go towards server costs.<br>
                You can send ALGO donations to this address:<br>
                <span class="addr">{{ EBISU_DONATION_ADDRESS }}</span>
            </div>
        </p>
        <p class="block">
            <span class="icon is-large">
                <fa-icon icon="fa-solid fa-torii-gate"></fa-icon>
            </span>
            <div>
                Ebisu is the Japanese god of fishermen and luck.<br>
                He is one of the Seven Gods of Fortune.
            </div>
        </p>
    </Modal>
</template>

<style lang="scss" scoped>
.modal-content {
    width: 800px;
}
.block {
    display: flex;
    font-size: 1.1rem;
    .icon {
        font-size: 1.5rem;
        padding-right: 1rem;
    }
}
.addr {
    font-size: .9rem;
    word-break: break-all;
}
</style>
