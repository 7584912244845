<script setup>
import { vOnClickOutside } from '@vueuse/components'
import { ref, watch } from 'vue'
import { useRoute } from "vue-router";

import MountedTeleport from '@/components/MountedTeleport.vue';
import SidebarMenu from '@/components/SidebarMenu.vue';
import { useWindowSize } from '@/use/use-window-size.js'

const route = useRoute()
const { isMobile } = useWindowSize()

const isMenuOpen = ref(false)

function closeMenu () {
    if (isMobile.value && isMenuOpen.value) {
        isMenuOpen.value = false
    }
}

watch(() => route.fullPath, closeMenu)

watch([isMobile, isMenuOpen], () => {
    const { documentElement, body } = document
    if (isMobile.value && isMenuOpen.value) {
        documentElement.style.overflowY = 'hidden'
        body.style.overflowY = 'hidden'
    } else {
        documentElement.style.overflowY = ''
        body.style.overflowY = ''
    }
})
</script>

<template>
<MountedTeleport to="#teleport-navbar-left">
    <SidebarMenu v-model="isMenuOpen" class="is-hidden-tablet"/>
</MountedTeleport>

<MountedTeleport to="body">
    <div v-show="isMobile && isMenuOpen" class="modal-background"></div>
</MountedTeleport>

<Transition name="sidebar">
    <div
        v-show="!isMobile || isMenuOpen"
        v-on-click-outside="closeMenu"
        class="sidebar">
        <SidebarMenu v-model="isMenuOpen"/>
        <hr>
        <a href="https://app.folks.finance/" class="folks-link" target="_blank">
            <img src="@/assets/images/folks-finance-logo.webp">
            <span class="icon">
                <fa-icon icon="fa-solid fa-up-right-from-square"/>
            </span>
        </a>
        <aside class="menu">
            <p class="menu-label">
                Global Data
            </p>
            <ul class="menu-list">
                <li>
                    <RouterLink to="/folks-finance/markets" custom v-slot="{ isActive, href, navigate }">
                        <!-- Treat active as exact-active, this should always be highlighted when on /markets -->
                        <a
                            :href="href"
                            @click="navigate"
                            :class="isActive ? 'router-link-exact-active' : ''"
                        >
                            Markets
                        </a>
                    </RouterLink>
                </li>
                <li>
                    <RouterLink to="/folks-finance/loans">
                        Loans <span class="tag is-info soon-tag">Soon<sup>TM</sup></span>
                    </RouterLink>
                </li>
                <li>
                    <RouterLink to="/folks-finance/liquidations">
                        Liquidations <span class="tag is-info soon-tag">Soon<sup>TM</sup></span>
                    </RouterLink>
                </li>
                <li>
                    <RouterLink to="/folks-finance/lending-pools">
                        Lending Pools <span class="tag is-info soon-tag">Soon<sup>TM</sup></span>
                    </RouterLink>
                </li>
            </ul>
            <p class="menu-label">
                Your Dashboard
            </p>
            <ul class="menu-list">
                <li>
                    <RouterLink to="/folks-finance/your-dashboard/loan-history">
                        Loan History <span class="tag is-info soon-tag">Soon<sup>TM</sup></span>
                    </RouterLink>
                </li>
                <li>
                    <RouterLink to="/folks-finance/your-dashboard/alerts">
                        Alerts <span class="tag is-info soon-tag">Soon<sup>TM</sup></span>
                    </RouterLink>
                </li>
                <li>
                    <RouterLink to="/folks-finance/your-dashboard/backfill-data">
                        Backfill Data <span class="tag is-info soon-tag">Soon<sup>TM</sup></span>
                    </RouterLink>
                </li>
            </ul>
            <p class="menu-label">
                General
            </p>
            <ul class="menu-list">
                <li>
                    <RouterLink to="/pricing">
                        Pricing
                    </RouterLink>
                </li>
            </ul>
        </aside>
    </div>
</Transition>
</template>

<style lang="scss" scoped>
.sidebar {
    background: $alt-background-color;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;

    hr {
        margin: 0;
    }
}

@media (max-width: $tablet) {
    .sidebar {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 100;
    }
}

.app-name {
  font-family: Scratched-Man;
  font-size: 2rem;
  line-height: 2rem;
  user-select: none;
  color: $text;
  text-align: center;
}

a.folks-link {
    @extend .flex-center;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 0.25rem;
    text-decoration: inherit;
    color: inherit;
    border-radius: $default-border-radius;
    cursor: pointer;
    gap: 0.5rem;
    transition: background-color $default-transition-duration;
    img {
        max-width: 130px;
    }
    .icon {
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        right: -8px;
        transition: opacity $default-transition-duration;
    }
    &:hover {
        background: $scheme-main-ter;
        .icon {
            opacity: 1;
        }
    }
}

.menu-label {
    color: lightgray;
}
.menu-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;

    a {
        display: flex;
        gap: 1rem;
        transition: background-color $default-transition-duration;
        border-radius: $default-border-radius;

        &.router-link-exact-active {
            background: $scheme-main-ter;
        }
        &:hover {
            background: $scheme-main-ter;
        }
    }
    .soon-tag {
        height: 20px;
        margin-left: auto;
        sup {
            font-size: .5rem;
            transform: translateY(-25%);
        }
    }
}

.sidebar-enter-active,
.sidebar-leave-active {
  transition: 250ms;
}

.sidebar-enter-from,
.sidebar-leave-to {
    transform: translateX(-100%);
}

</style>
