<script setup>
import { onMounted, ref } from 'vue'

defineOptions({
    inheritAttrs: false
})
const isMounted = ref(false)
onMounted(() => isMounted.value = true)
</script>

<template>
<Teleport v-bind="$attrs" v-if="isMounted"><slot></slot></Teleport>
</template>
