import {
    Chart,
    ArcElement,
    BarElement,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Interaction,
    Legend,
    TimeScale,
} from 'chart.js'
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import zoomPlugin from 'chartjs-plugin-zoom';
import CrosshairPlugin from './crosshair-plugin'

Chart.register(
    ArcElement,
    BarElement,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ChartDataLabels,
    CrosshairPlugin,
    zoomPlugin,
)

const { hover, elements, scale, plugins } = Chart.defaults
//console.log(Chart.defaults)

const defaultColor = '#fff'
const defaultColorLight = '#cecece'

Chart.defaults.color = '#fff'
Chart.defaults.font.family = '"Roboto", "Helvetica Neue", "Helvetica", sans-serif'
Chart.defaults.responsive = true
Chart.defaults.maintainAspectRatio = false
Chart.defaults.animation.duration = 500

elements.point.radius = 0
elements.point.hoverRadius = 3
elements.point.hoverBackgroundColor = defaultColor

elements.line.borderWidth = 1.5
elements.line.borderColor = defaultColor
elements.line.backgroundColor = defaultColorLight

hover.mode = 'index'
hover.intersect = false
hover.animationDuration = 0

scale.grid.color = 'rgba(255,255,255,.1)'

plugins.datalabels.display = false
