<script setup>
import { computed, ref } from 'vue'
import { Bar } from 'vue-chartjs'

import ChartContainer from '@/components/charts/ChartContainer.vue'
import ChartLegend from '@/components/charts/ChartLegend.vue'

const props = defineProps({
    config: Object,
    isLoading: Boolean
})

defineEmits(['reorderChartKeys'])

const bar = ref(null)

const legendItems = ref([])
const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart) {
        legendItems.value = chart.options.plugins.legend.labels.generateLabels(chart);
    }
}
const chartHeight = computed(() => {
    const numItems = props.config.data.datasets[0].data.length
    const legendHeight = 35
    const heightPerItem = 40
    return (numItems * heightPerItem) + legendHeight
})
</script>

<template>
<ChartContainer :isLoading="isLoading" :style="{height: `${chartHeight}px`}">
    <template v-slot:legend>
    <ChartLegend :chartRef="bar" :items="legendItems">
        <template v-slot:end>
            <button @click="$emit('reorderChartKeys')" class="button is-dark is-responsive">
                <span class="icon is-small">
                    <fa-icon icon="fa-solid fa-arrow-right-arrow-left" />
                </span>
            </button>
        </template>
    </ChartLegend>
    </template>
    <Bar v-bind="config" ref="bar" :plugins="[htmlLegendPlugin]"/>
</ChartContainer>
</template>

<style lang="scss" scoped>

</style>
