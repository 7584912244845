import { WalletManager, defaultState } from '@txnlab/use-wallet-js'
import { inject, computed } from 'vue'

import { ref, readonly } from 'vue'

export const WalletManagerPlugin = {
    install(app, options) {
        const manager = new WalletManager(options)
        const updateTracker = ref(0)

        let state = ref({ ...defaultState, activeNetwork: options.network })

        manager.subscribe((newState) => {
            state.value = { ...newState }
            updateTracker.value++
        })

        const resumeSessions = async () => {
            try {
                await manager.resumeSessions()
                updateTracker.value++
            } catch (error) {
                console.error('Error resuming wallet sessions:', error)
            }
        }

        resumeSessions()

        app.provide('walletManager', manager)
        app.provide('walletState', readonly(state))
        app.provide('walletUpdateTracker', updateTracker)
    }
}

export function useWallet() {
    const manager = inject('walletManager')
    const stateRef = inject('walletState')
    const updateTrackerRef = inject('walletUpdateTracker')

    if (!manager || !stateRef) {
        throw new Error('WalletManager plugin is not properly installed')
    }
    const activeNetwork = computed(() => stateRef.value.activeNetwork)

    const _computed = (cb) => {
        return computed(() => {
            updateTrackerRef.value // create reactive dependency
            return cb()
        })
    }

    const wallets = _computed(() => manager.wallets)
    const algodClient = _computed(() => manager.algodClient)
    const activeWalletAccounts = _computed(() => manager.activeWalletAccounts)
    const activeWalletAddresses = _computed(() => manager.activeWalletAddresses)
    const activeAccount = _computed(() => manager.activeAccount)
    const activeAddress = _computed(() => manager.activeAddress)
    const activeWallet = computed(() => manager.activeWallet)
    const setActiveNetwork = _computed(() => manager.setActiveNetwork)

    const signTransactions = (
        txnGroup,
        indexesToSign,
        returnGroup
    ) => {
        if (!activeWallet.value) {
            throw new Error('No active wallet')
        }

        const fn = manager.signTransactions.bind(manager.activeWallet)
        return fn(txnGroup, indexesToSign, returnGroup)
    }

    const transactionSigner = (txnGroup, indexesToSign) => {
        if (!activeWallet.value) {
            throw new Error('No active wallet')
        }
        const fn = manager.transactionSigner.bind(manager.activeWallet)
        return fn(txnGroup, indexesToSign)
    }

    return {
        stateRef,
        wallets,
        algodClient,
        activeNetwork,
        activeWallet,
        activeWalletAccounts,
        activeWalletAddresses,
        activeAccount,
        activeAddress,
        setActiveNetwork,
        signTransactions,
        transactionSigner
    }
}
