<script setup>
import { vOnClickOutside } from '@vueuse/components'

const props = defineProps({
    modelValue: Boolean,
    overflowVisible: Boolean,
    // Clicked CSS selector which won't close the modal
    allowedOutsideClickSelectors: Array
})
const emit = defineEmits(['update:modelValue'])

const walletModalSelectors = [
    'pera-wallet-connect-modal',
    'defly-wallet-connect-modal',
    // TODO: Exodus
    'wcm-modal',
]

function onClickOutside (e) {
    const allowedSelectors = walletModalSelectors.concat(props.allowedOutsideClickSelectors)
    const matches = allowedSelectors.some(s => e.target.matches(s))
    if (!matches) {
        emit('update:modelValue', false)
    }
}
</script>

<template>
    <Teleport to="body">
        <Transition name="modal">
            <div
                v-if="modelValue"
                v-on-click-outside="onClickOutside"
                class="modal is-active px-2">
                <div @click="$emit('update:modelValue', false)" class="modal-background"></div>
                    <div class="modal-content" :class="{'overflow-visible': overflowVisible}">
                        <button @click="$emit('update:modelValue', false)" class="modal-close is-large "></button>
                        <slot></slot>
                    </div>
            </div>
        </Transition>
    </Teleport>
</template>

<style lang="scss" scoped>
.modal-content {
    position: relative;
    padding: 1rem;
    background: $scheme-main-ter;
    border-radius: $default-border-radius;
    transition: transform 0.3s ease;
    &.overflow-visible {
        overflow: visible;
    }
    .modal-close {
        position: absolute;
        right: 1px;
        top: 1px;
    }
}

.modal-close::before,
.modal-close::after {
    background-color: white;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease;
}
.modal-enter-from .modal-content,
.modal-leave-to .modal-content {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
