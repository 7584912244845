<script setup>
const props = defineProps({
    chartRef: Object,
    items: Object,
})


function toggleItem (item){
    // No point in toggling a single series chart
    if (props.items.length === 1) {
        return
    }

    const { chart } = props.chartRef

    if (chart.isDatasetVisible(item.datasetIndex)) {
        chart.hide(item.datasetIndex);
    } else {
        chart.show(item.datasetIndex);
    }
}
</script>

<template>
<div class="legend-toolbar">
    <div class="legend-start">
        <slot name="start"></slot>
    </div>
    <TransitionGroup name="list" tag="div" class="legend">
        <div
            v-for="i in items"
            :key="i.text"
            @click="toggleItem(i)"
            class="legend-item">
            <div class="legend-color" :style="{background: i.fillStyle}"></div>
            <span class="legend-text" :class="{'has-text-grey': i.hidden}">{{ i.text }}</span>
        </div>
    </TransitionGroup>
    <div class="legend-end">
        <slot name="end"></slot>
    </div>
</div>
</template>

<style lang="scss" scoped>
.legend-toolbar {
    flex-basis: 35px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 0.85rem;
}
.legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.85rem;
    font-size: .8rem;

    .legend-item {
        display: flex;
        align-items: center;
        gap: .25rem;
        cursor: pointer;
    }

    .legend-color {
        width: 12px;
        height: 12px;
        margin-bottom: 2px;
    }

    .legend-text {
        transition: color $default-transition-duration;
    }
}
</style>
