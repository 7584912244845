
export const fmtPercent = (val, moreOpts) => {
    const opts = {
        style: 'percent',
        minimumFractionDigits: 2,
        ...moreOpts,
    }
    return new Intl.NumberFormat('en-US', opts).format(val)
}

export const fmtShortCurrency = (val, moreOpts) => {
    const opts = {
        style: 'currency',
        currency: 'USD',
        notation: "compact",
        compactDisplay: "short",
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
        ...moreOpts
    }
    return new Intl.NumberFormat('en-US', opts).format(val)
}

export const fmtLongCurrency = (val, moreOpts) => {
    const opts = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        ...moreOpts
    }
    return new Intl.NumberFormat('en-US', opts).format(val)
}

export const fmtShortDecimal = (val) => {
    const opts = {
        style: 'decimal',
        notation: "compact",
        compactDisplay: "short",
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    }
    return new Intl.NumberFormat('en-US', opts).format(val)
}


export const fmtShortInteger = (val) => {
    const opts = {
        notation: "compact",
        compactDisplay: "short",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }
    return new Intl.NumberFormat('en-US', opts).format(val)
}
