<script setup>
import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker';

import { useDateRangeStore, ranges, DATA_START_DATE, NOW_KEY } from '@/stores/date-range-store';
import Dropdown from '@/components/Dropdown.vue'

const store = useDateRangeStore()

const isDropdownActive = ref(false)

const datepickerLabel = computed(() => {
    const range = ranges.find(r => r.key === store.from)
    if (range) {
        return range.label
    } else {
        const fromStr = dayjs(store.fromDate).format('YYYY-MM-DD')
        const toStr = dayjs(store.toDate).format('YYYY-MM-DD')
        return `${fromStr} to ${toStr}`
    }
})

const selectedFrom = ref(store.fromDate)
const selectedTo = ref(store.toDate)

const fromMinDate = DATA_START_DATE
const fromMaxDate = computed(() => dayjs.utc(selectedTo.value).subtract(1, 'day').toDate())

const toMinDate = computed(() => dayjs.utc(selectedFrom.value).add(1, 'day').toDate())
const toMaxDate = computed(() => dayjs.utc().add(1, 'day').toDate())

watch([() => store.fromDate, () => store.toDate], () => {
    selectedFrom.value = store.fromDate
    selectedTo.value = store.toDate
})

function applyTimeRange() {
    store.setFrom(selectedFrom.value)
    store.setTo(selectedTo.value)
    isDropdownActive.value = false
}

function setRelativeDate (key) {
    store.setFrom(key)
    store.setTo(NOW_KEY)
    isDropdownActive.value = false
}
</script>

<template>
  <Dropdown v-model="isDropdownActive" triggerClass="is-responsive" noDropdownIcon>
    <template v-slot:button-icon>
        <span class="icon is-small">
            <fa-icon icon="fa-regular fa-clock"/>
        </span>
    </template>
    <template v-slot:button-text>
        {{ datepickerLabel }}
    </template>
    <template v-slot:dropdown-content>
        <div class="datepicker-dropdown">
            <div class="absolute-ranges">
                <div>
                    <span>From</span>
                    <VueDatePicker
                        v-model="selectedFrom"
                        :min-date="fromMinDate"
                        :max-date="fromMaxDate"
                        :enable-time-picker="false"
                        :clearable="false"
                        :year-range="[2023, new Date().getFullYear()]"
                        prevent-min-max-navigation
                        dark />
                </div>
                <div>
                    <span>To</span>
                    <VueDatePicker
                        v-model="selectedTo"
                        :min-date="toMinDate"
                        :max-date="toMaxDate"
                        :enable-time-picker="false"
                        :clearable="false"
                        :year-range="[2023, new Date().getFullYear()]"
                        prevent-min-max-navigation
                        dark />
                </div>
                <button @click="applyTimeRange" class="button is-dark is-responsive mt-2">
                    Apply time range
                </button>
            </div>
            <div class="buttons relative-ranges">
                <button
                    v-for="r in ranges"
                    :key="r.key"
                    class="button is-dark is-fullwidth is-responsive"
                    :class="{'is-active': r.key === store.from}"
                    @click="setRelativeDate(r.key)">
                    {{ r.label }}
                </button>
            </div>
        </div>
    </template>
  </Dropdown>
</template>

<style lang="scss" scoped>
$calendar-width: 170px;

.datepicker-dropdown {
    display: grid;
    grid-template-columns: $calendar-width 150px;
    gap: .5rem;

    @media (max-width: $tablet) {
        grid-template-columns: $calendar-width;
    }
}

.absolute-ranges {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.relative-ranges {
    max-height: 300px;
    overflow-y: auto;
    @media (max-width: $tablet) {
        border-top: 1px solid $white-dim;
    }
}

:deep(.dropdown-menu) {
    max-width: unset;
    left: unset;
    right: 0;
}
</style>
