<template>
  <div class="has-text-centered">
    <p class="subtitle">404 Page Not Found</p>
    <RouterLink to="/" class="button is-dark">
        Home
    </RouterLink>
  </div>
</template>

<style>

</style>
