<script setup>
import { ref } from 'vue'

import { useWallet } from '@/use/use-wallet.js'
import TransactionModal from '@/components/TransactionModal.vue'
import ConnectWallet from '@/components/ConnectWallet.vue'
import { makeJoinWaitlistTxn } from '@/txns.js'

const props = defineProps({
    features: Array,
    planName: String
})

const { activeAddress } = useWallet()
const isOpen = ref(false)
const selectedFeatures = ref([])

const makeTxnFn = () => {
    return makeJoinWaitlistTxn(
        activeAddress.value, props.planName, selectedFeatures.value)
}

</script>

<template>
    <button @click="isOpen = true" class="button is-dark is-responsive">
        Join Waitlist
    </button>
    <TransactionModal
        v-model="isOpen"
        :makeTxnFn="makeTxnFn"
        :showSubmitButton="!!activeAddress"
        overflowVisible>
        <div class="modal-content-inner">
            <div class="has-text-centered">
                <p class="block">
                    <p class="title is-4">
                        Join Waitlist
                    </p>
                </p>
                <div v-if="activeAddress">
                    <p class="block">
                        Which features are you most interested in?
                    </p>
                    <div class="plan-features block">
                        <label v-for="f in features" :key="f">
                            <input type="checkbox" v-model="selectedFeatures" :value="f.id"/>
                            <span class="ml-2">{{ f.name }}</span>
                        </label>
                    </div>
                </div>
                <template v-else>
                    <p class="block">
                        Connect your wallet in order to sign up for the waitlist.
                    </p>
                    <ConnectWallet/>
                </template>
            </div>
        </div>
        <template v-slot:success-text>
            <div class="py-4">
                Thanks for joining the wait list!<br>
                Ebisu is currently in beta.<br>
                New features will be released when ready. 😉
            </div>
        </template>
        <template v-slot:txn-details>
            <div class="help">
                Transaction details: You are sending 0 ALGO to the Ebisu wallet for tracking purposes.
            </div>
        </template>
    </TransactionModal>
</template>

<style lang="scss" scoped>

.plan-features {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: left;
}
</style>
