<script setup>
import { storeToRefs } from 'pinia'
import NumberAnimation from "vue-number-animation";
import { ref, shallowRef, watch, computed } from 'vue';

import { ALL_ASSETS } from 'common/constants.js';
import AssetList from '@/components/AssetList.vue';
import LineChart from '@/components/charts/LineChart.vue';
import BarChart from '@/components/charts/BarChart.vue';
import {
    usdChartConfig, amountChartConfig, aprChartConfig, usdBarChartConfig,
    aprBarChartConfig,
} from '@/lib/charts/chart-config.js'
import { fmtPercent, fmtLongCurrency } from '@/format.js'
import { useDateRangeStore } from '@/stores/date-range-store';
import * as api from '@/api'

const props = defineProps({
    assetId: String
})

const isOverview = computed(() => props.assetId === ALL_ASSETS)
const isInitialLoad = ref(true)
const isLoading = ref(null)
const fetchErr = ref(null)

const store = useDateRangeStore()
const { fromTimestamp, toTimestamp, resolution, lastUpdated } = storeToRefs(store)

const assetData = shallowRef([])
const assetLatestUsd = shallowRef({})
const charts = shallowRef([])
const stats = ref([])
const transitionKey = ref(null)

const assetUsdBarChartKeys = ref(['depositUsd', 'borrowUsd'])
const assetInterestBarChartKeys = ref(['depositApr', 'borrowAprVariable', 'borrowAprStable'])

function rotateArray (arrayRef) {
    const arr = arrayRef.value
    arrayRef.value = [...arr.slice(1, arr.length), arr[0]]
}

function onDaterangeUpdate([xMin, xMax]) {
    store.setFrom(xMin)
    store.setTo(xMax)
}

// use watcher to prevent chart from updating immediately when from/to changes, but data is still fetching.
// only update chart when the data changes.
watch([assetData, assetUsdBarChartKeys, assetInterestBarChartKeys], () => {
    const data = assetData.value
    const from = fromTimestamp.value
    const to = toTimestamp.value

    if (isOverview.value) {
        charts.value = [
            { config: usdChartConfig(data, from, to, ['depositUsd']) },
            { config: usdChartConfig(data, from, to, ['borrowUsd', 'borrowUsdStable', 'borrowUsdVariable']) },
            { config: usdBarChartConfig(assetLatestUsd.value, assetUsdBarChartKeys.value), keyNamesRef: assetUsdBarChartKeys, component: BarChart },
            { config: aprBarChartConfig(assetLatestUsd.value, assetInterestBarChartKeys.value), keyNamesRef: assetInterestBarChartKeys, component: BarChart },
        ]
    } else {
        charts.value = [
            { config: usdChartConfig(data, from, to, ['depositUsd', 'borrowUsd', 'borrowUsdStable', 'borrowUsdVariable', 'borrowUsdCap']) },
            { config: amountChartConfig(data, from, to, ['depositAmount', 'borrowAmount', 'borrowAmountVariable', 'borrowAmountStable']) },
            { config: aprChartConfig(data, from, to, ['depositApr', 'borrowAprVariable', 'borrowAprStable', 'rewardsApr']) },
            { config: aprChartConfig(data, from, to, ['depositApy', 'borrowApyVariable', 'borrowApyStable']) },
        ]
    }

    transitionKey.value = isOverview.value ? 'a' : 'b'

    const lastValue = data.slice().reverse().find(d => d.depositUsd)
    if (lastValue) {
        let statsToShow = [
            { key: 'Deposited', value: lastValue.depositUsd, fmt: fmtLongCurrency},
            { key: 'Borrowed', value: lastValue.borrowUsd, fmt: fmtLongCurrency},
        ]

        if (!isOverview.value) {
            statsToShow = statsToShow.concat([
                { key: 'Deposit APR', value: lastValue.depositApr, fmt: fmtPercent},
                { key: 'Borrow APR Variable (v)', value: lastValue.borrowAprVariable, fmt: fmtPercent},
                { key: 'Borrow APR Stable (s)', value: lastValue.borrowAprStable, fmt: fmtPercent},
            ])
        }
        stats.value = statsToShow
    }
})

watch(
    [() => props.assetId, resolution, fromTimestamp, toTimestamp, lastUpdated],
    async () => {
        isLoading.value = true
        fetchErr.value = null

        try {
            const promises = [
                api.fetchFolksTimeSeriesData(
                    props.assetId,
                    resolution.value,
                    fromTimestamp.value,
                    toTimestamp.value)
            ]
            if (isOverview.value) {
                promises.push(api.fetchFolksLatestAssetUsd())
            }

            [assetData.value, assetLatestUsd.value] = await Promise.all(promises)
        } catch (err) {
            cl(err)
            fetchErr.value = 'Error loading data.'
        } finally {
            isLoading.value = false
            isInitialLoad.value = false
        }
    },
    {
        immediate: true,
    }
)
</script>

<template>
<div class="markets-view">
    <AssetList :assetId="assetId" class="assets-grid-area"/>
    <div class="stats-grid-area">
        <Transition mode="out-in">
            <div v-if="!fetchErr" :key="transitionKey" class="stats has-text-centered text-no-wrap">
                <div v-for="stat in stats" :key="stat.key">
                    <div class="heading is-size-6 ">{{ stat.key }}</div>
                    <div class="title is-5">
                        <NumberAnimation
                            :to="stat.value"
                            :format="stat.fmt"
                            :duration=".5"
                            autoplay
                            easing="linear"
                        />
                    </div>
                </div>
            </div>
        </Transition>
    </div>
    <div class="charts-grid-area">
        <Transition mode="out-in">
            <div v-if="isInitialLoad && isLoading">
                <div class="is-loading mt-4"></div>
            </div>
            <div v-else-if="fetchErr" class="has-text-centered">
                {{ fetchErr }}
            </div>
            <div v-else :key="transitionKey"  class="charts">
                <component v-for="c in charts"
                    @reorderChartKeys="rotateArray(c.keyNamesRef)"
                    @update:dateRange="onDaterangeUpdate"
                    :is="c.component ?? LineChart"
                    :config="c.config"
                    :isLoading="isLoading"
                    :key="c.config.id"/>
            </div>
        </Transition>
    </div>
</div>
</template>

<style lang="scss" scoped>
.markets-view {
    display: grid;
    gap: $default-gap;
    grid-template:
        "stats stats" minmax(65px, max-content)
        "assets charts" 1fr / min-content 1fr;

    @media (max-width: $tablet) {
        grid-template:
            "assets"
            "stats"
            "charts"
    }
}

.stats-grid-area {
    grid-area: stats;
    grid-column: 2 / 3;
    @media (max-width: $desktop) {
        grid-column: 1 / 3;
    }
    @media (max-width: $tablet) {
        grid-column: unset;
    }
}

.stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: $default-gap;
    > * {
        padding: 0.5rem 1rem;
        flex: 1;
    }
}

@media (max-width: $tablet) {
    .stats {
        gap: 1rem;
        > *  {
            flex-basis: 100%;
        }
    }
}

:deep(.assets-grid-area) {
    grid-area: assets;
}

.charts-grid-area {
    grid-area: charts;
}

.charts {
    display: grid;
    gap: $default-gap;
    grid-template-columns: minmax(250px, 1fr);

    @media (min-width: $widescreen) {
        grid-template-columns: repeat(2, minmax(250px, 1fr));
    }
}

</style>
