<script setup>
import { shallowRef, reactive } from 'vue'
import { Chart, Tooltip } from 'chart.js'
import { toFont } from 'chart.js/helpers';

const initialConfig = () => ({
    classes: '',
    style: {},
})

const config = reactive(initialConfig())
const ttModel = shallowRef({})

Tooltip.positioners.cursor = (chartElements, coordinates) => ({
    x: coordinates.x + 0,
    y: coordinates.y + 0,
})

Chart.defaults.plugins.tooltip.boxPadding = 2
Chart.defaults.plugins.tooltip.mode = 'index'
Chart.defaults.plugins.tooltip.intersect = false
Chart.defaults.plugins.tooltip.position = 'cursor'
Chart.defaults.plugins.tooltip.enabled = false

Chart.defaults.plugins.tooltip.callbacks.label = context => {
    const label = context.dataset.label
    // return array so vue can render table cells
    return [label, context.raw.toLocaleString()];
}

Chart.defaults.plugins.tooltip.external = context => {
    Object.assign(config, initialConfig());

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    ttModel.value = tooltipModel
    if (tooltipModel.opacity === 0) {
        config.style.display = 'none'
        return
    }

    // Set caret Position
    if (tooltipModel.yAlign) {
        config.classes = tooltipModel.yAlign
    } else {
        config.classes = 'no-transform'
    }

    const position = context.chart.canvas.getBoundingClientRect();
    const bodyFont = toFont(tooltipModel.options.bodyFont);

    Object.assign(config.style, {
        display: 'block',
        left: position.left + window.scrollX + tooltipModel.caretX + 10 + 'px',
        top: position.top + window.scrollY + tooltipModel.caretY + 10 + 'px',
        font: bodyFont.string,
        padding: tooltipModel.options.padding + 'px ' + tooltipModel.options.padding + 'px',
    })
}
</script>

<template>
<div class="chartjs-tooltip" :style="config.style" :class="config.classes">
    <div v-for="line in ttModel.title || []" :key="line" class="mb-1 has-text-weight-bold">
        {{ line }}
    </div>
    <table>
        <tbody>
            <tr v-for="(line, i) in ttModel.body?.map(b => b.lines) || []" :key="line">
                <td >
                    <div :style="{
                        width: ttModel.options.boxWidth + 'px',
                        height: ttModel.options.boxHeight + 'px',
                        paddingRight: ttModel.options.boxPadding + 'px',
                        background: ttModel.labelColors[i].backgroundColor,
                        borderColor: ttModel.labelColors[i].borderColor,
                        borderWidth: '2px'
                    }"></div>
                </td>
                <template v-if="Array.isArray(line)">
                    <td v-for="l in line" :key="l">
                        {{ l }}
                    </td>
                </template>
                <td v-else>{{ line }}</td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<style lang="scss" scoped>
.chartjs-tooltip {
    position: absolute;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.8);
    border-radius: $default-border-radius;

    td {
        padding: 2px;
    }
}
</style>
