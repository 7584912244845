<script setup>
import { useClipboard } from '@vueuse/core'
import { ref } from 'vue'
//import { useWallet } from '@txnlab/use-wallet-vue'
import { useWallet } from '@/use/use-wallet.js'

import Dropdown from '@/components/Dropdown.vue'

const wallet = useWallet()
//cl(wallet)
const { wallets, activeAddress, activeWallet } = wallet

const btnClass = 'button is-dark is-fullwidth is-responsive'
const isOpen = ref(false)

const { copy } = useClipboard()

// function walletImgUrl (walletId) {
//     return new URL(`@/assets/images/wallets/${walletId}.svg`, import.meta.url).href
// }

// Dynamic import with variables not working, even though vite docs says it does
// https://github.com/vitejs/vite/issues/11157
const walletImgs = {
    defly: new URL(`@/assets/images/wallets/defly.svg`, import.meta.url),
    exodus: new URL(`@/assets/images/wallets/exodus.svg`, import.meta.url),
    pera: new URL(`@/assets/images/wallets/pera.svg`, import.meta.url),
    walletconnect: new URL(`@/assets/images/wallets/walletconnect.svg`, import.meta.url),
}

function disconnectWallet () {
    activeWallet.value.disconnect()
    isOpen.value = false
}
</script>

<template>

<Dropdown v-model="isOpen" triggerClass="is-responsive" noDropdownIcon>
    <template v-slot:button-icon>
        <span v-show="activeAddress" class="icon">
            <fa-icon v-show="activeAddress" icon="fa-solid fa-wallet"/>
        </span>
    </template>
    <template v-slot:button-text>
        {{ activeAddress ? activeAddress.slice(0, 3) : 'Connect Wallet' }}
    </template>
    <template v-slot:dropdown-content>
        <template v-if="activeAddress">
            <div class="buttons">
                <button @click="copy(activeAddress)" :class="btnClass">
                    <span class="icon mr-3">
                        <fa-icon icon="fa-regular fa-copy"/>
                    </span>
                    <span>Copy Address</span>
                </button>
                <a
                    :href="`https://allo.info/account/${activeAddress}/`"
                    target="_blank"
                    :class="btnClass">
                    <span class="icon mr-3">
                        <fa-icon icon="fa-solid fa-up-right-from-square"/>
                    </span>
                    <span>Explorer</span>
                </a>
                <button @click="disconnectWallet" :class="btnClass">
                    <span class="icon mr-3">
                        <fa-icon icon="fa-solid fa-arrow-right-from-bracket"/>
                    </span>
                    <span>Disconnect</span>
                </button>
            </div>
        </template>
        <div v-else class="buttons">
            <button
                v-for="wallet in wallets"
                @click="wallet.connect()"
                :key="wallet.id"
                :class="[btnClass, 'wallet-button']">
                <span class="icon mr-3">
                    <img :src="walletImgs[wallet.id]"/>
                </span>
                <span>{{ wallet.metadata.name }}</span>
            </button>
        </div>
    </template>
</Dropdown>
</template>

<style lang="scss" scoped>
.trigger {
    background-color: #232526;  /* fallback for old browsers */
    background-color: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.button {
    justify-content: start;
}
.wallet-button {
    img {
        border-radius: $default-border-radius;
    }
}

:deep(.dropdown-menu) {
    left: unset;
    right: 0;
}
</style>
