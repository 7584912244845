<script setup>
import { useDateRangeStore } from '@/stores/date-range-store';
import DatePicker from '@/components/DatePicker.vue';
import Info from '@/components/Info.vue'
import ConnectWallet from '@/components/ConnectWallet.vue'

const store = useDateRangeStore()
</script>

<template>
  <header class="container is-widescreen">
    <div id="teleport-navbar-left"></div>
    <div class="spacer"></div>
    <div id="teleport-navbar-right"></div>
    <Info/>
    <button @click="store.refresh()" class="button is-dark is-responsive">
        <span class="icon is-small">
            <fa-icon icon="fa-solid fa-refresh" />
        </span>
    </button>
    <DatePicker/>
    <ConnectWallet/>
  </header>
</template>

<style lang="scss" scoped>
.container {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: .5rem 2rem;

    @media (max-width: $tablet) {
        gap: 0.5rem;
        padding: .5rem;
    }
}


.spacer {
    margin-left: auto;
}
</style>
