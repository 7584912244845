import * as Sentry from "@sentry/vue"
import { parse } from 'csv-parse/browser/esm/sync';

const API_URL = import.meta.env.VITE_API_URL

export async function fetchFolksLatestAssetUsd () {
    const url = new URL(`${API_URL}/folks/latest-asset-usd`)

    let res
    try {
        res = await fetch(url)
        if (!res.ok) {
            throw new Error((await res.json()).message)
        }
    } catch (err) {
        Sentry.captureException(err)
        throw err
    }

    const csv = await res.text()
    let data = parse(csv, {
        columns: true,
        cast: true,
        cast_date: true
    })
    return data
}

export async function fetchFolksTimeSeriesData (assetId, resolution, from, to) {
    const url = new URL(`${API_URL}/folks/${assetId}`)
    url.searchParams.set('resolution', resolution)
    url.searchParams.set('from', from)
    url.searchParams.set('to', to)

    let res
    try {
        res = await fetch(url)
        if (!res.ok) {
            throw new Error((await res.json()).message)
        }
    } catch (err) {
        Sentry.captureException(err)
        throw err
    }

    const csv = await res.text()
    let data = parse(csv, {
        columns: true,
        cast: true,
        cast_date: true
    })
    data = data.map(d => ({ ...d, timestamp: new Date(d.timestamp)}))
    return data
}
