<script setup>
import { computed, ref, watch } from 'vue'

import Modal from '@/components/Modal.vue'
import { useWallet } from '@/use/use-wallet.js'
import { submitTxn } from '@/txns.js'

defineOptions({
    inheritAttrs: false
})
const props = defineProps({
    modelValue: Boolean,
    makeTxnFn: Function,
    showSubmitButton: Boolean
})
const emit = defineEmits(['update:modelValue'])

const STATES = {
    DEFAULT: 'DEFAULT',
    SIGNING: 'SIGNING',
    SUBMITTING: 'SUBMITTING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
}
const loadingStates = [STATES.SIGNING, STATES.SUBMITTING]

const wallet = useWallet()

const state = ref(STATES.DEFAULT)
const submitResult = ref(null)
const errMsg = ref(null)

const isLoading = computed(() => loadingStates.includes(state.value))
const buttonText = computed(() => {
    if (state.value === STATES.SUCCESS) {
        return 'Close'
    } else if (state.value === STATES.ERROR) {
        return 'Resubmit'
    } else {
        return 'Submit'
    }
})

watch(() => props.modelValue, val => {
    if (!val) {
        resetState()
    }
})

function resetState () {
    state.value = STATES.DEFAULT
    errMsg.value = null
}

function onButtonClick() {
    if (state.value === STATES.SUCCESS) {
        emit('update:modelValue', false)
    } else if (state.value === STATES.ERROR || state.value === STATES.DEFAULT) {
        onSubmit()
    }
}

async function onSubmit() {
    state.value = STATES.SIGNING

    let txns
    try {
        txns = await props.makeTxnFn()
        txns = Array.isArray(txns) ? txns : [txns]
    } catch (err) {
        console.error(err)
        errMsg.value = err?.message ?? 'Failed to create transaction.'
        state.value = STATES.ERROR
        return
    }

    let signedTxns
    try {
        signedTxns = await wallet.signTransactions(txns)
    } catch (err) {
        console.error(err)
        errMsg.value = err?.message ?? 'Failed to sign transaction.'
        state.value = STATES.ERROR
        return
    }

    state.value = STATES.SUBMITTING

    try {
        const res = await submitTxn(signedTxns)

        state.value = STATES.SUCCESS
        submitResult.value = res
    } catch (err) {
        console.error(err)
        state.value = STATES.ERROR
        errMsg.value = err?.message ?? 'Failed to submit transaction.'
    }
}

</script>

<template>
    <Modal
        v-bind="$attrs"
        :modelValue="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)">
        <div v-if="state === STATES.ERROR" class="submit-result">
            <div class="status">
                <fa-icon icon="fa-regular fa-circle-xmark" size="2x" class="has-text-danger"/>
                Transaction error
            </div>
            <div>{{ errMsg }}</div>
            <slot name="error-text"></slot>
        </div>
        <div v-else-if="state === STATES.SUCCESS" class="submit-result">
            <div class="status">
                <fa-icon icon="fa-regular fa-circle-check" size="2x" class="has-text-success"/>
                Transaction submitted
            </div>
            <a
                :href="`https://allo.info/tx/${submitResult?.txId}`"
                target="_blank"
                class="has-text-link">
                <fa-icon icon="fa-solid fa-up-right-from-square" class="mr-2"/>
                View on Explorer
            </a>
            <slot name="success-text"></slot>
        </div>
        <div v-else>
            <slot></slot>
        </div>
        <div v-show="showSubmitButton" class="has-text-centered mt-5">
            <slot v-if="state !== STATES.SUCCESS" name="txn-details"></slot>
            <button
                @click="onButtonClick"
                :class="{'is-loading': isLoading}"
                class="button is-success mt-3">
                {{ buttonText }}
            </button>
        </div>
    </Modal>
</template>

<style lang="scss" scoped>

.submit-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
    padding: 0.5rem;
    word-break: break-all; // algod has long err messages
    .status {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}
</style>
