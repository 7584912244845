import humanizeString  from 'humanize-string'
import { merge } from 'lodash-es'

import * as d3 from '@/d3.js'
import { fmtPercent, fmtShortCurrency, fmtShortDecimal, fmtShortInteger } from '@/format.js'

const lineChartOptions = (startDate, endDate, customOpts) => {
    const defaultOpts = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                min: startDate,
                max: endDate,
                ticks: {
                    maxTicksLimit: 10,
                }
            },
            y: {
                ticks: {
                    maxTicksLimit: 8,
                },
                afterFit (scaleInstance) {
                    scaleInstance.width = 60
                }
            }
        },
        layout: {
            padding: {
                right: 10,
                bottom: 10,
            }
        },
        plugins: {
            legend: {
                display: false, // using HTML legend
            },
            crosshair: {
                line: {
                    color: '#808c99',
                    width: 1,
                    dashPattern: [2, 2]
                },
            }
        }
    }
    return merge(defaultOpts, customOpts)
}

const horizontalBarChartOptions = (customOpts) => {
    const defaultOpts = {
        indexAxis: 'y',
        interaction: {
            axis: 'y'
        },
        layout: {
            padding: {
                right: 30,
            }
        },
        plugins: {
            legend: {
                display: false, // using HTML legend
            },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
            }
        }
    }
    return merge(defaultOpts, customOpts)
}

// https://materializecss.com/color.html
function getColor (key) {
    const colorScale = d3.scaleSequential(d3.interpolateSpectral);
    key = key.toLowerCase()
    if (key.includes('deposit')) {
        return '#4caf50' // green
    } else if (key.includes('cap')) {
        return '#7986cb' // indigo lighten-2
    } else if (key.includes('variable')) {
        return '#ffc107' // amber
    } else if (key.includes('stable')) {
        return '#00bcd4' // cyan
    } else if (key.includes('borrow')) {
        return '#f44336' // red
    } else if (key.includes('rewards')) {
        return '#9c27b0' // purple
    } else {
        return colorScale(key)
    }
}

const assetColors = {
    "goETH": "#9d9798",
    "WETH": "#9d9798", // find alt color
    "goBTC": "#f7931a",
    "WBTC": "#f7931a",  // find alt color
    "USDt": "#26a17b",
    "gALGO": "#1c99d7",
    "USDC": "#2e79cc",
    "WSOL": "#9945ff",
    "WLINK": "#375bd2",
    "WAVAX": "#e84142",
    "WMPL": "#f28619",
    "ALGO": "#000",
    "STBL2": "dark-purple",
    "EURS": "#15bcff",
    "GOLD": "#f9bb06",
    "SILVER": "#94979d",
}

function commonConfig (data, keyNames) {
    const keys = Object.keys(data[0] ?? {}).filter(key => keyNames.includes(key))
    return {
        labels: data.map(d => d.timestamp),
        keys,
        humanizedKeys: humanizeKeys(keys)
    }
}

function humanizeKeys (keys) {
    return keys.map(key => {
        return humanizeString(key)
            .replace(/usd/, '$')
            .replace(/apr/, 'APR')
            .replace(/apy/, 'APY')
            .replace(/variable/, '(v)')
            .replace(/stable/, '(s)')
            .replace(/amount/, 'Amount')
    })
}

export function usdBarChartConfig (data, keyNames) {
    data = data.sort((a, b) => b[keyNames[0]] - a[keyNames[0]])
    const labels = data.map(d => d.assetName)

    const humanizedKeys = humanizeKeys(keyNames)

    const datasets = keyNames.map((key, i) => ({
        label: humanizedKeys[i],
        data: data.map(d => d[key]),
        backgroundColor: getColor(key),
        borderColor: getColor(key),
        hidden: key.includes('Variable') || key.includes('Stable'),
        barPercentage: .5,
    }))

    const customOpts = {
        scales: {
            x: {
                type: 'logarithmic',
                ticks: {
                    maxTicksLimit: 8,
                    callback: val => fmtShortCurrency(val,{
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })
                }
            }
        },
        plugins: {
            datalabels: {
                formatter: val => fmtShortInteger(val),
            }
        }
    }
    const options = horizontalBarChartOptions(customOpts)
    const config = {
        type: 'bar',
        data: {
            labels,
            datasets
        },
        options
    }
    return config
}

export function aprBarChartConfig (data, keyNames) {
    data = data.sort((a, b) => b[keyNames[0]] - a[keyNames[0]])
    const labels = data.map(d => d.assetName)
    const humanizedKeys = humanizeKeys(keyNames)

    const datasets = keyNames.map((key, i) => ({
        label: humanizedKeys[i],
        data: data.map(d => d[key]),
        backgroundColor: getColor(key),
        borderColor: getColor(key),
        barPercentage: .5,
        hidden: key.includes('Stable'),
    }))

    const customOpts = {
        scales: {
            x: {
                ticks: {
                    callback: val => fmtPercent(val, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: context => {
                        const label = context.dataset.label || '';
                        return [label, fmtPercent(context.parsed.x)];
                    }
                },
            },
            datalabels: {
                display: false,
            }
        }
    }
    const options = horizontalBarChartOptions(customOpts)
    const config = {
        type: 'bar',
        data: {
            labels,
            datasets
        },
        options
    }
    return config
}

export function usdChartConfig (data, startDate, endDate, keyNames) {
    const { labels, keys, humanizedKeys } = commonConfig(data, keyNames)

    const datasets = keys.map((key, i) => ({
        label: humanizedKeys[i],
        data: data.map(d => d[key]),
        backgroundColor: getColor(key),
        borderColor: getColor(key),
        borderDash: key.includes('Cap') ? [10,5] : [],
        hidden: key.includes('Variable') || key.includes('Stable') || key.includes('Cap')
    }))

    const customOpts = {
        scales: {
            y: {
                ticks: {
                    callback: (val) => fmtShortCurrency(val)
                }
            }
        },
    }
    const options = lineChartOptions(startDate, endDate, customOpts)

    return {
        data: {
            labels,
            datasets
        },
        options
    }
}

export function amountChartConfig (data, startDate, endDate, keyNames) {
    const { labels, keys, humanizedKeys } = commonConfig(data, keyNames)

    const datasets = keys.map((key, i) => ({
        label: humanizedKeys[i],
        data: data.map(d => d[key]),
        backgroundColor: getColor(key),
        borderColor: getColor(key),
        hidden: key.includes('Variable') || key.includes('Stable')
    }))

    const customOpts = {
        scales: {
            y: {
                ticks: {
                    callback: (val) => fmtShortDecimal(val)
                }
            }
        },
    }
    const options = lineChartOptions(startDate, endDate, customOpts)

    return {
        data: {
            labels,
            datasets
        },
        options
    }
}

export function aprChartConfig (data, startDate, endDate, keyNames) {
    const { labels, keys, humanizedKeys } = commonConfig(data, keyNames)

    const datasets = keys
        .filter(key => {
            const isRewardsKey = key.includes('rewards')
            const hasRewards = isRewardsKey && data.map(d => d[key]).some(d => d > 0)

            return !isRewardsKey || hasRewards
        })
        .map((key, i) => ({
            label: humanizedKeys[i],
            data: data.map(d => d[key]),
            backgroundColor: getColor(key),
            borderColor: getColor(key),
        }))

    const customOpts = {
        scales: {
            y: {
                ticks: {
                    callback: (val) => fmtPercent(val)
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: context => {
                        const label = context.dataset.label || '';
                        return [label, fmtPercent(context.parsed.y)];
                    }
                }
            }
        }
    }
    const options = lineChartOptions(startDate, endDate, customOpts)

    return {
        data: {
            labels,
            datasets
        },
        options
    }
}
