<script setup>
import { computed, ref, watch } from 'vue';

import { ALL_ASSETS } from 'common/constants.js';
import Dropdown from '@/components/Dropdown.vue'
import { assets } from '@/ff-assets';
import AssetListItem from './AssetListItem.vue';

const props = defineProps({
    assetId: [Number, String],
})

const isDropdownActive = ref(false)

const allAssets = [{
    symbol: ALL_ASSETS,
    label: 'All',
    img: new URL(`@/assets/images/folks-finance-icon.webp`, import.meta.url)
}].concat(assets)

const activeAsset = computed(() => allAssets.find(a => a.symbol === props.assetId))

watch(() => props.assetId, () => isDropdownActive.value = false)
</script>

<template>
<div v-bind="$attrs" class="asset-list is-hidden-mobile">
    <AssetListItem v-for="a in allAssets" :asset="a" :key="a.symbol"/>
</div>
<Dropdown v-model="isDropdownActive" class="is-hidden-tablet">
    <template v-slot:button-icon>
        <span class="icon is-small mr-2">
            <img :src="activeAsset.img">
        </span>
    </template>
    <template v-slot:button-text>
        {{ activeAsset.label ?? activeAsset.symbol }}
    </template>
    <template v-slot:dropdown-content>
        <AssetListItem
            v-for="a in allAssets"
            :asset="a"
            :key="a.symbol"
            class="dropdown-item"/>
    </template>
</Dropdown>
</template>

<style lang="scss" scoped>
$width: 120px;

.asset-list {
    display: flex;
    flex-direction: column;
    color: $text;
    gap: 5px;
    min-width: $width;
    a {
        color: $text;
    }
}

.dropdown {
    justify-self: center;
}

:deep(.dropdown-content) {
    min-width: $width;
    max-height: min(400px, 100dvh);
    overflow-y: scroll;
    overscroll-behavior-y: contain;
}
</style>
