<script setup>
import { Chart } from 'chart.js'
import { cloneDeep, debounce, merge } from 'lodash-es'
import { ref, computed } from 'vue'
import { Line } from 'vue-chartjs'

import ChartContainer from '@/components/charts/ChartContainer.vue'
import ChartLegend from '@/components/charts/ChartLegend.vue'

const props = defineProps({
    config: Object,
    isLoading: Boolean
})

const emit = defineEmits(['update:dateRange'])

const chart = ref(null)

const legendItems = ref([])
const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart) {
        legendItems.value = chart.options.plugins.legend.labels.generateLabels(chart);
    }
}

const syncCharts = debounce(({ chart }) => {
    const xMin = chart.scales.x.min
    const xMax = chart.scales.x.max

    Object.values(Chart.instances)
        .filter(ci => ci.config.options.plugins.zoom)
        .forEach(ci => {
            ci.options.scales.x.min = xMin
            ci.options.scales.x.max = xMax
            ci.update()
        })

    emit('update:dateRange', [Math.round(xMin), Math.round(xMax)])
}, 200)

// Define zoom config here in order to emit date range updates.
const zoomConfig = {
    options: {
        plugins: {
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                    onPanComplete : syncCharts
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    drag: {
                        enabled: false
                    },
                    mode: 'x',
                    onZoomComplete : syncCharts
                }
            }
        }
    }
}

const newConfig = computed(() => {
    const newConfig = cloneDeep(props.config)
    return merge(newConfig, zoomConfig)
})
</script>

<template>
<ChartContainer :isLoading="isLoading">
    <template v-slot:legend>
        <ChartLegend :chartRef="chart" :items="legendItems"/>
    </template>
    <Line v-bind="newConfig" ref="chart" :plugins="[htmlLegendPlugin]"/>
</ChartContainer>
</template>

<style lang="scss" scoped>

</style>
