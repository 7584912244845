import algosdk, { assignGroupID, waitForConfirmation } from 'algosdk'
import { EBISU_MAIN_ADDRESS, ALGO_BURN_ADDRESS } from "common/constants";

const ALGONODE_ORIGIN = 'https://mainnet-api.algonode.cloud'

const algodClient = new algosdk.Algodv2('', ALGONODE_ORIGIN)
const maxWaitConfirmationRounds = 10

export async function makeJoinWaitlistTxn (senderAddr, plan, features) {
    const featureIds = features.join(',')
    const note = `waitlist-${plan}-${featureIds}`
    const addr = import.meta.env.DEV
        ? ALGO_BURN_ADDRESS
        : EBISU_MAIN_ADDRESS

    const suggestedParams = await algodClient.getTransactionParams().do();
    const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from: senderAddr,
        suggestedParams,
        to: addr,
        amount: 0,
        //amount: 100000000, // test txn errors
        note: new TextEncoder().encode(note),
    });

    return txn
}

export async function submitTxn (signedTxns) {
    const { txId } = await algodClient.sendRawTransaction(signedTxns).do()
    const res = await waitForConfirmation(algodClient, txId, maxWaitConfirmationRounds)
    res.txId = txId
    return res
}
