<script setup>
import { useDateRangeStore } from '@/stores/date-range-store';
defineProps({
    asset: Object,
})
const store = useDateRangeStore()
</script>

<template>
<router-link
    :to="`${asset.symbol}?from=${store.from}&to=${store.to}`"
    :key="asset.symbol"
    class="asset">
    <figure class="image is-24x24">
        <img :src="asset.img">
    </figure>
    <span>{{ asset.label ?? asset.symbol }}</span>
</router-link>
</template>

<style lang="scss" scoped>
.asset {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0.375em;
    padding: 0.5rem;
    gap: 1rem;
    transition: background-color $default-transition-duration;
    a {
        color: $text;
    }
    &:hover {
        background: $alt-background-color;
        color: $text;
    }
    &.router-link-active {
        background: $alt-background-color;
    }
}

</style>
