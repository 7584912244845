window.cl = console.log

// fix "ReferenceError: global is not defined at ../node_modules/@walletconnect/socket-transport/dist/esm/index.js
window.global ||= window

import '@/assets/scss/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faClock, faEnvelope, faCopy, faFaceGrinSquintTears, faCircleCheck,
    faCircleXmark
} from '@fortawesome/free-regular-svg-icons'
import {
    faRefresh, faInfo, faAngleDown, faToriiGate, faArrowRightArrowLeft,
    faWallet, faArrowRightFromBracket, faUpRightFromSquare, faCheck, faGlobe,
    faBell, faChartLine, faScaleUnbalanced, faBars

} from '@fortawesome/free-solid-svg-icons'
import { NetworkId, WalletId } from '@txnlab/use-wallet-js'

import '@vuepic/vue-datepicker/dist/main.css'

//import { WalletManagerPlugin } from '@txnlab/use-wallet-vue'
import { WalletManagerPlugin } from '@/use/use-wallet.js'

import Fragment from '@/components/Fragment.vue'
import App from './App.vue'
import router from './router'
import '@/lib/charts/chartjs-defaults.js'
import '@/sentry.js'

library.add(faClock)
library.add(faRefresh)
library.add(faInfo)
library.add(faAngleDown)
library.add(faToriiGate)
library.add(faEnvelope)
library.add(faArrowRightArrowLeft)
library.add(faArrowRightFromBracket)
library.add(faUpRightFromSquare)
library.add(faWallet)
library.add(faCopy)
library.add(faCheck)
library.add(faGlobe)
library.add(faBell)
library.add(faChartLine)
library.add(faScaleUnbalanced)
library.add(faFaceGrinSquintTears)
library.add(faBars)
library.add(faCircleCheck)
library.add(faCircleXmark)

const app = createApp(App)

app.component('fa-icon', FontAwesomeIcon)

app.use(createPinia())
app.use(router)

app.use(WalletManagerPlugin, {
    wallets: [
        WalletId.DEFLY,
        //WalletId.EXODUS,
        WalletId.PERA,
        {
            id: WalletId.WALLETCONNECT,
            options: { projectId: '09d609630dc7523e8c629f385a0fa489' }
        },
       // WalletId.KMD
    ],
    network: NetworkId.MAINNET
})

app.component('VFragment', Fragment)

router.isReady().then(() => app.mount('#app'))

//cl(process.env.VITE_API_URL)
