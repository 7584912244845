export const assets = [
    {
        symbol: 'ALGO',
        img: new URL(`@/assets/images/tokens/ALGO.svg`, import.meta.url)
    },
    {
        symbol: 'gALGO',
        img: new URL(`@/assets/images/tokens/gALGO.svg`, import.meta.url)
    },
    {
        symbol: 'USDC',
        img: new URL(`@/assets/images/tokens/USDC.svg`, import.meta.url)
    },
    {
        symbol: 'USDt',
        img: new URL(`@/assets/images/tokens/USDt.svg`, import.meta.url)
    },
    {
        symbol: 'EURS',
        img: new URL(`@/assets/images/tokens/EURS.svg`, import.meta.url)
    },
    {
        symbol: 'goBTC',
        img: new URL(`@/assets/images/tokens/goBTC.svg`, import.meta.url)
    },
    {
        symbol: 'goETH',
        img: new URL(`@/assets/images/tokens/goETH.svg`, import.meta.url)
    },
    {
        symbol: 'WBTC',
        img: new URL(`@/assets/images/tokens/WBTC.png`, import.meta.url)
    },
    {
        symbol: 'WETH',
        img: new URL(`@/assets/images/tokens/WETH.png`, import.meta.url)
    },
    {
        symbol: 'WAVAX',
        img: new URL(`@/assets/images/tokens/WAVAX.png`, import.meta.url)
    },
    {
        symbol: 'WSOL',
        img: new URL(`@/assets/images/tokens/WSOL.png`, import.meta.url)
    },
    {
        symbol: 'WLINK',
        img: new URL(`@/assets/images/tokens/WLINK.png`, import.meta.url)
    },
    {
        symbol: 'GOLD',
        img: new URL(`@/assets/images/tokens/GOLD.png`, import.meta.url)
    },
    {
        symbol: 'SILVER',
        img: new URL(`@/assets/images/tokens/SILVER.png`, import.meta.url)
    },
    {
        symbol: 'OPUL',
        img: new URL(`@/assets/images/tokens/OPUL.svg`, import.meta.url)
    },
    {
        symbol: 'WMPL',
        img: new URL(`@/assets/images/tokens/WMPL.png`, import.meta.url)
     }
];
