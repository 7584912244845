<script setup>
import { computed } from 'vue'
import JoinWaitlist from '@/components/JoinWaitlist.vue';

const loanHistory = 'Loan History'
const loanHistoryOne = '1 Loan History'
const loanHistoryAll = 'All Loans History'
const loanExplorer = 'Global Loan Explorer'
const liqExplorer = 'Liquidation Explorer'
const lpHistory = 'Lending Pool History'
// 1 address only
const walletHistory = 'Wallet Balance History'
const notifications = 'Interest Rate Alerts'
const dataCollection = 'Backfill Data'

const features = [
    {
        id: 1,
        name: loanHistory,
        text: "View your account's lending positions, net worth, accrued interest, and more.",
        icon: 'fa-solid fa-chart-line',
        iconColor: '#4caf50'
    },
    {
        id: 2,
        name: loanExplorer,
        text: "Analyze other users' lending positions.",
        icon: 'fa-solid fa-globe',
        iconColor: '#2196f3'
    },
    {
        id: 3,
        name: liqExplorer,
        text: "Get front row seats to watch degens getting liquidated.",
        icon: 'fa-regular fa-face-grin-squint-tears',
        iconColor: '#eebb99'
    },
    {
        id: 4,
        name: lpHistory,
        text: "Display your lending pool balances to visualize the impact of Impermanent Loss.",
        icon: 'fa-solid fa-scale-unbalanced',
        iconColor: '#9575cd'
    },
    {
        id: 5,
        name: walletHistory,
        hide: true,
    },
    {
        id: 6,
        name: notifications,
        text: "Receive customizable notifications on interest rates.",
        icon: 'fa-solid fa-bell',
        iconColor: '#ffc107'
    },
    {
        id: 7,
        name: dataCollection,
        hide: true,
    },
]
const displayedFeatures = computed(() => features.filter(f => !f.hide))

const freePlan = {
    name: 'Free',
    price: '1 month free',
    features: [
        loanHistoryOne,
        loanExplorer,
        liqExplorer,
    ]
}

const basicPlan = {
    name: 'Basic',
    price: '$3/month',
    features: freePlan.features
}

const proPlan = {
    name: 'Pro',
    price: '$8/month',
    features: [
        loanHistoryAll,
        loanExplorer,
        liqExplorer,
        lpHistory,
        walletHistory,
        notifications,
        dataCollection
    ]
}
const plans = [freePlan, basicPlan, proPlan]

</script>

<template>
<div class="">
    <section class="hero" :style="{background: '#5c6bc0'}">
        <div class="hero-body">
            <p class="title">
                Ebisu offers subscriptions for personalized data history.
            </p>
        </div>
    </section>
    <div class="section">
        <p class="title has-text-centered is-2 mb-6">Features</p>
        <div class="features">
            <div v-for="f in displayedFeatures" :key="f.feature">
                <fa-icon :icon="f.icon" size="2x" fixed-width :style="{color: f.iconColor}"/>
                <div>
                    <p class="title is-4">
                        {{ f.name }}
                    </p>
                    <p class="subtitle">
                        {{ f.text }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="section has-text-centered">
        <p class="title is-2">Plans</p>
        <p class="subtitle is-6">All plans are paid in USDC<sup>1</sup></p>
        <div class="plans my-6">
            <div v-for="plan in plans" :class="['plan', `${plan.name.toLowerCase()}-plan`]" :key="plan.name">
                <div class="header">{{ plan.name }}</div>
                <div class="body">
                    <div class="price is-size-4">{{ plan.price }}</div>
                    <hr>
                    <div class="plan-features">
                        <template v-for="f in plan.features" :key="f">
                            <fa-icon icon="fa-solid fa-check"/>
                            <span>{{ f }}<sup v-if="f === dataCollection">2</sup></span>
                        </template>
                    </div>
                    <div class="wait-list">
                        <JoinWaitlist :features="features" :planName="plan.name"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="notes has-text-left">
            <div class="block">
                <sup>1</sup>&nbsp;Algorand doesn't support automated payments, so plans must be manually renewed every month.<br>
                You can also purchase multiple months for a discount.
            </div>
            <div class="block">
                <sup>2</sup>&nbsp;Data collection for "history" features starts on the date you subscribe to a plan.<br>
                For data that exists prior to this date, you can purchase data in monthly blocks for $1/month.<br>
                Backfilled data is available starting from August 11, 2023.
            </div>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
.hero {
    display: grid;
    justify-content: center;
    border-radius: $default-border-radius;
}
.features {
    display: grid;
    justify-content: center;
    gap: 3rem;
    text-align: left;
    > * {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: start;
        gap: $default-gap;
    }

}
.plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $default-gap;

}

$free-color: #4caf50;
$basic-color: #2196f3;
$pro-color: #673ab7;

.plan {
    display: flex;
    flex-direction: column;
    border: 1px solid $white-dim;
    border-radius: $default-border-radius;
    overflow: hidden;
    width: 300px;

    .header {
        padding: 0.5rem;
    }
    .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        .price {
            padding: 1rem 0;
        }
        .plan-features {
            display: grid;
            grid-template-columns: 20px auto;
            align-items: center;
            justify-content: center;
            padding: 1rem 0;
            gap: 1rem;
            text-align: left;
            svg {
                color: #66bb6a;
            }
        }
        .wait-list {
            margin-top: auto;
            padding-top: 1rem;
        }
    }
    &.free-plan {
        .header {
            background: #4caf50;
        }
    }
    &.basic-plan {
        .header {
            background: #2196f3;
        }
    }
    &.pro-plan {
        .header {
            background: #673ab7;
        }
    }
}

.notes {
    margin-top: 6rem;
    font-size: .9rem;
    display: grid;
    justify-content: center;
}

:deep(sup) {
    line-height: 0;
}
</style>
