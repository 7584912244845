import { useWindowSize as useWindowSizeCore } from '@vueuse/core'
import { computed } from 'vue'

export function useWindowSize() {
    const { width, height } = useWindowSizeCore()

    const mobileWidth = 768 // bulma
    const isMobile = computed(() => width.value <= mobileWidth)

    return {
        width,
        height,
        isMobile
    }
}
